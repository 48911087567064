:root {
  --border-color: white;
  --logo-width: calc((100vw - 10px) / 3);
}
@media only screen and (min-width: 600px) {
  :root {
    --logo-width: calc((100vw / 2 - 20px) / 3);
  }
}

@media only screen and (min-width: 800px) {
  :root {
    --logo-width: calc((100vw / 3 - 30px) / 3);
  }
}
@media only screen and (min-width: 1200px) {
  :root {
    --logo-width: calc((100vw / 4 - 40px) / 3);
  }
}

.deck {
  border: solid 1px var(--border-color);
  border-radius: 7px;
  overflow: hidden;
  color: white;
  cursor: pointer;
}
.deck section {
  min-width: 100%;
}

.deck h3,
.deck p{
  margin: 1rem;
}
.deck-logo {
  display: grid;
  max-width: 100%;
  justify-items: center;
}
.deck-logo .image {
  min-width: 100%;
  height: 200px;
  background-size: cover;
  background-position: top center;
}

.commander1-companion1,
.commander2-companion0 {
  grid-template-columns: 1fr 1fr;
}
.commander1-companion0 {
  grid-template-columns: 1fr;
}
.commander2-companion0 {
  grid-template-columns: 1fr 1fr;
}
.commander2-companion1 {
  grid-template-columns: 1fr 1fr 1fr;
}

.artist-attribution {
  margin: 0 0 10px;
}
