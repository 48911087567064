.deck-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  gap: 10px;
}
.App > header {
  text-align: center;
}
.App > header img {
  max-width: 100vw;
}
.social-links {
  min-height: 100vh;
}
.social-links li {
  list-style-type: persian;
  margin:0.5rem;
}
.social-links pre {
  display: inline;
  background: black;
  padding: 0 0.5rem;
}

@media only screen and (min-width: 600px) {
  .deck-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 800px) {
  .deck-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (min-width: 1200px) {
  .deck-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
